<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Merchant</span>
                <span class="headline" v-else>Create New Merchant</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Merchant Code*" :readonly="formType === 'UPDATE'" v-model="form.merchantCode" required hint="one word, camel case *MerchantCode*" :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Merchant Name*" v-model="form.merchantName" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Webhook*" v-model="form.webhook" required hint="callback URL" :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Success URL*" v-model="form.successUrl" required hint="redirect URL for successful transaction" :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Fail URL*" v-model="form.failUrl" required hint="redirect URL for failed transaction" :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="formType === 'UPDATE' && hasPermission('AUTH_UPDATE_MERCHANT_SECRET')">
                                <v-text-field label="Secret Key*" v-model="form.secretKey" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                                <v-select :items="[3000, 5000, 10000, 15000, 30000, 60000, 300000]" label="Connection Timeout (ms)*" v-model="form.connectionTimeout" required :rules="[rules.requiredField]"></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                                <v-select :items="[3000, 5000, 10000, 15000, 30000, 60000, 300000]" label="Read Timeout (ms)*" v-model="form.readTimeout" required :rules="[rules.requiredField]"></v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Merchant Logo URL*" v-model="form.merchantLogoUrl" required :rules="[rules.requiredField]"></v-text-field>
                            </v-flex>
                            <v-card
                              class="mx-auto"
                              outlined
                            >
                              <v-flex xs12 ref="paymentOptions">
                                <v-row v-for="(paymentOption,k) in form.paymentOptionsRate" :key="k">
                                    <v-col lg="5" xl="5" md="12" sm="12" xs="12" cols="12">
                                        <v-autocomplete :item-disabled="disablePaymentOption" :items="paymentOptions" :item-text="(row) => {return row.walletSystem.displayName+ ' - ' + row.name;}" item-value="code" label="Payment Method*" v-model="paymentOption.method" required :rules="[rules.requiredSelection]" @input="handleChangePaymentOptions($event)">
                                          <template slot="selection" slot-scope="data">
                                            {{ data.item.walletSystem.displayName }} - {{ data.item.name }}
                                          </template>
                                          <template slot="item" slot-scope="data">
                                            {{ data.item.walletSystem.displayName }} - {{ data.item.name }}
                                          </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col lg="4" xl="4" md="9" sm="9" xs="7" cols="7">
                                        <v-text-field label="Conversion Rate*" v-model="paymentOption.rate" required min="0.1" hint="Conversion rate with ratio 1:N where N > 0" :rules="[rules.requiredField]"></v-text-field>
                                    </v-col>
                                    <v-col lg="1" xl="1" md="1" sm="1" xs="2" cols="2" v-show="k == form.paymentOptionsRate.length-1" class="mt-3">
                                        <v-btn
                                          fab
                                          dark
                                          x-small
                                          color="green"
                                          class="mr-2"
                                          @click="addPaymentOptions(k)"
                                        >
                                          <v-icon dark medium>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col lg="1" xl="1" md="1" sm="1" xs="2" cols="2" v-show="form.paymentOptionsRate.length == 1"  class="mt-3">
                                        <v-btn
                                          fab
                                          dark
                                          x-small
                                          color="red"
                                          class="mr-2"
                                          @click="clearPaymentOptions(k)"
                                        >
                                          <v-icon dark medium>mdi-close</v-icon>
                                        </v-btn>
                                      </v-col>
                                    <v-col lg="1" xl="1" md="1" sm="1" xs="2" cols="2" v-show="k || ( !k && form.paymentOptionsRate.length > 1)"  class="mt-3">
                                        <v-btn
                                          fab
                                          dark
                                          x-small
                                          color="red"
                                          class="mr-2"
                                          @click="removePaymentOptions(k)"
                                        >
                                          <v-icon dark medium>mdi-delete</v-icon>
                                        </v-btn>
                                      </v-col>
                                </v-row>
                              </v-flex>
                            </v-card>
                            <v-flex xs12>
                                <v-text-field label="Remarks" v-model="form.remarks"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="formType === 'UPDATE'">
                                <v-switch v-model="form.isEnabled" :label="`Is Enabled: ${form.isEnabled ? form.isEnabled.toString() : 'false'}`"></v-switch>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="formType === 'UPDATE'">
                                <v-switch v-model="form.isWebhookEnabled" :label="`Is Webhook Enabled: ${form.isWebhookEnabled ? form.isWebhookEnabled.toString() : 'false'}`"></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>

import {
    mapGetters
} from 'vuex';

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        paymentMethods: Array,
        selectedItem: Object
    },
    computed: {
      ...mapGetters([
            'authorities'
        ]),
        populatedForm() {
            return {
                merchantCode: this.form.merchantCode,
                merchantName: this.form.merchantName,
                webhook: this.form.webhook,
                successUrl: this.form.successUrl,
                failUrl: this.form.failUrl,
                secretKey: this.form.secretKey,
                connectionTimeout: this.form.connectionTimeout,
                readTimeout: this.form.readTimeout,
                isEnabled: this.form.isEnabled,
                remarks: this.form.remarks,
                formType: this.formType,
                merchantLogoUrl: this.form.merchantLogoUrl,
                isWebhookEnabled: this.form.isWebhookEnabled,
                paymentOptionsRate: this.form.paymentOptionsRate
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        paymentMethods: {
            handler(value) {
                this.paymentOptions = value
            }
        },
        'form.paymentOptionsRate': {
            handler(after, before) {
                this.selectedPaymentOptions = []
                for(var item in after) {
                    if(!this.selectedPaymentOptions.includes(after[item].method)) {
                        this.selectedPaymentOptions.push(after[item].method)
                    }
                }

                console.log(this.selectedPaymentOptions)
            },
            deep: true
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            paymentOptions: this.paymentMethods ?? [],
            form: {},
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            },
            selectedPaymentOptions: [],
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        addPaymentOptions(index) {
          this.form.paymentOptionsRate.push({
            method: '',
            rate: ''
          });
        },
        removePaymentOptions(index) {
          const value =  this.form.paymentOptionsRate[index]
          this.selectedPaymentOptions.splice(this.selectedPaymentOptions.indexOf(value), 1);
          this.form.paymentOptionsRate.splice(index, 1);
          console.log(this.selectedPaymentOptions)
        },
        clearPaymentOptions(index) {
          this.form.paymentOptionsRate = [{method: "", rate: ""}]
        },
        handleChangePaymentOptions(e) {
        //   if(!this.selectedPaymentOptions.includes(e)) {
        //     this.selectedPaymentOptions.push(e)
        //   }
        //   console.log(this.selectedPaymentOptions)
        },
        disablePaymentOption(item) {
          return this.selectedPaymentOptions.includes(item.code);
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-merchant', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    merchantCode: undefined,
                    merchantName: undefined,
                    webhook: undefined,
                    successUrl: undefined,
                    failUrl: undefined,
                    secretKey: undefined,
                    connectionTimeout: '3000',
                    readTimeout: '15000',
                    isEnabled: false,
                    remarks: '',
                    merchantLogoUrl: undefined,
                    isWebhookEnabled: false,
                    paymentOptionsRate: [{method: "", rate: ""}]
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    merchantCode: this.selectedItem.code,
                    merchantName: this.selectedItem.name,
                    webhook: this.selectedItem.webhook,
                    successUrl: this.selectedItem.successUrl,
                    failUrl: this.selectedItem.failUrl,
                    secretKey: this.selectedItem.secret,
                    connectionTimeout: this.selectedItem.connectTimeout,
                    readTimeout: this.selectedItem.readTimeout,
                    isEnabled: this.selectedItem.isEnabled,
                    remarks: this.selectedItem.remarks,
                    merchantLogoUrl: this.selectedItem.merchantLogoUrl,
                    isWebhookEnabled: this.selectedItem.isWebhookEnabled,
                    paymentOptionsRate: this.selectedItem.merchantPaymentMethods ? this.selectedItem.merchantPaymentMethods.length > 0 ? this.selectedItem.merchantPaymentMethods : [{method: "", rate: ""}] : [{method: "", rate: ""}]
                }
            }
        }
    }
}
</script>

<style scoped>
.input-group--select__autocomplete {
    height: 0px !important;
}

.input-group--select.input-group--focused .input-group--select__autocomplete {
    height: 30px !important;
}
</style>
